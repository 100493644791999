import React from "react";
import { Notifications } from "../../integration/Notifications";
import AseclaDataContext from "../../store/AseclaDataContext";
import { ContextProps } from "../../type/ContextProps";
import { useMutation } from "@tanstack/react-query";
import { queryClient } from "../../App";
import { requestServer } from "../../store/ServerConnection";
import StoreLicenseRequest from "../../type/request/StoreLicenseRequest";
import { LicenseTypeResponse } from "../../type/responses/LicenseTypeResponse";
import { LicenseType } from "../../type/LicenseType";
import GenerateLicenseCodeRequest from "../../type/request/GenerateLicenseCodeRequest";
import { LicenseCodeGeneratedResponse } from "../../type/responses/LicenseCodeGeneratedResponse";
import DeleteLicenseType from "../../type/request/DeleteLicenseType";
import { t } from "i18next";
import { TANSTACK_LICENSE_TYPES_STATS_KEY } from "../useLicenseTypes";
import { LicenseStatsResponse } from "../../type/responses/LicenseStatsResponse";

export function useCreateNewLicenseType() {
    const { serverError, serverSuccess } = Notifications();
    const props = React.useContext(AseclaDataContext) as ContextProps;

    const { mutate: createLicenseType } = useMutation({
        mutationFn: (req: StoreLicenseRequest) => {
            return requestServer("storeLicenseType", req, props);
        },
        onSuccess: (ret: LicenseStatsResponse) => {
            queryClient.setQueryData(TANSTACK_LICENSE_TYPES_STATS_KEY, ret)
            serverSuccess("License created");
        },
        onError: () => serverError("Failed to create license")
    });

   return { createLicenseType };
}

export function useModifyLicenseType(callback: (licenseType: LicenseType) => void) {
    const { serverError, serverSuccess } = Notifications();
    const props = React.useContext(AseclaDataContext) as ContextProps;

    const { mutate: modifyLicenseType } = useMutation({
        mutationFn: ({license}: any) => {
            return requestServer("modifyLicenseType", {license}, props);
        },
        onSuccess: (ret: LicenseTypeResponse) => {
            callback(ret.licenseType);
            serverSuccess("License saved");
        },
        onError: () => serverError("Failed to save license changes")
    });

   return { modifyLicenseType };
}

interface useGenerateLicenseCodeParams {
    request: GenerateLicenseCodeRequest,
    callback: (code: string) => void
}
export function useGenerateLicenseCode() {
    const { serverError, serverSuccess } = Notifications();
    const props = React.useContext(AseclaDataContext) as ContextProps;

    const { mutate: generateLicenseCode } = useMutation({
        mutationFn: (params: useGenerateLicenseCodeParams) => {
            return requestServer("generateLicenseCode", params.request, props);
        },
        onSuccess: (ret: LicenseCodeGeneratedResponse, params: useGenerateLicenseCodeParams) => {
            params.callback(ret.licenseCode.code);
            serverSuccess(t("License code generated"));
        },
        onError: () => serverError(t("Failed to generate license code"))
    });

   return { generateLicenseCode };
}

export function useDeleteLicenseType() {
    const { serverError, serverSuccess } = Notifications();
    const props = React.useContext(AseclaDataContext) as ContextProps;

    const { mutate: deleteLicenseType } = useMutation({
        mutationFn: (req: DeleteLicenseType) => {
            return requestServer("deleteLicenseType", req, props);
        },
        onSuccess: (ret: LicenseStatsResponse) => {
            queryClient.setQueryData(TANSTACK_LICENSE_TYPES_STATS_KEY, ret);
            serverSuccess(t("License code delete"));
        },
        onError: () => serverError(t("Failed to delete license code"))
    });

   return { deleteLicenseType };
}
