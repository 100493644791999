import { ValidationIssue } from "./ValidationIssue";

export type ValidationError = { 
	errorCode: number;
	msg: string;
	success: boolean;
	issues: ValidationIssue[];
}

export function getValidationIssues(err: ValidationError|null, field: string): ValidationIssue[]| null {
	if (err == null || err.issues == undefined) return null;
	let issues: ValidationIssue[] = [];
	for (let i = 0; i < err.issues.length; i++) {
		if (err.issues[i].field == field) {
			issues.push(err.issues[i]);
		}
	}
	if (issues.length == 0) return null;
	return issues;
}

export function isValidationIssue(serverResp: any) {
    if (serverResp === undefined) return false;
    return serverResp.errorCode != undefined
        && serverResp.msg != undefined
        && serverResp.success === false
        && serverResp.issues != undefined

}