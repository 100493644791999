import PageStructure from "../component/PageStructure";
import { t } from "i18next";
import React from "react";
import { ContextProps } from "../type/ContextProps";
import AseclaDataContext from "../store/AseclaDataContext";
import { OrganizationData } from "../type/responses/OrganizationData";
import { useOrganizations } from "../hooks/useOrganizations";
import { useMyRole } from "../hooks/queries/useMyRole";
import Loading from "../component/utils/Loading";
import IsErrorMessage from "../component/utils/IsErrorMessage";
import i18n from "../i18n";
import styled from "styled-components";
import TitleBanner from "../component/utils/TitleBanner";

interface RoleAccessRules {
    roles: string[],
    rule: string
}
function MyRolePage() {
    const props: ContextProps = React.useContext(AseclaDataContext) as ContextProps;

    const MANAGER: string = t("Manager"); 
    const USER_ADMIN: string = t("User Admin");

    const { isAseclaAdmin, organizations, isFetching: fetchingOrgs, isError: errOrgs } = useOrganizations();
    const { licenseCodes, isFetching, isError } = useMyRole();
    let curOrganization : OrganizationData | null = props.currentOrganizationNo === -1 ? null : (organizations??[])[props.currentOrganizationNo];

    if (isFetching || fetchingOrgs) {
        return <Loading></Loading>
    }
    if (isError || errOrgs) {
        return <IsErrorMessage></IsErrorMessage>
    }

    const RoleSettings = () => {
        if (curOrganization == null) {
            return <>{t("You are not assigned to organization, thus you are not having any role") as string}</>
        }
        
        let isUserAdmin = curOrganization.haveAdminRights;
        let isManager = curOrganization.haveManagerRights;

        let included: string[] = [];
        if (isAseclaAdmin) included.push(t("Asecla Admin"));
        if (isUserAdmin) included.push(USER_ADMIN);
        if (isManager) included.push(MANAGER);

        let excluded = [];
        if (!isUserAdmin) excluded.push(USER_ADMIN);
        if (!isManager) excluded.push(MANAGER);

        let hasActiveLicenseCode = false;
        for (let i = 0; i < licenseCodes.length; i++) {
            if (licenseCodes[i].activeNow) {
                hasActiveLicenseCode = true;
            }
        }

        let roleAccessRules: RoleAccessRules[] = [
            {
                rule: t("Has access to configuration panel."),
                roles: [MANAGER, USER_ADMIN]
            }, {
                rule: t("Has access to payment financial data, invoices and can manage payment methods."),
                roles: [MANAGER]
            }, {
                rule: t("May buy licenses"),
                roles: [MANAGER]
            }, {
                rule: t("May assign and remove Manager role."),
                roles: [MANAGER]
            }, {
                rule: t("May manage users, grant access and licenses and manage Business Administrator role"),
                roles: [USER_ADMIN]
            }, {
                rule: t("Will get email reminder about end of license comming soon"),
                roles: [MANAGER, USER_ADMIN]
            }, {
                rule: t("Sees Licenses"),
                roles: [MANAGER]
            }
        ]

        interface FieldParams {
            roles: string[]
            role: string
        }
        const Field = ({roles, role}: FieldParams) => {
            if (roles.includes(role)) {
                if (included.includes(role)) {
                    return <StyledPositiveCell>{t("Yes")}</StyledPositiveCell>               
                } else {
                    return <td>{t("Yes") as string}</td>               
                }
            }
            return <td>-</td>
        }

        const getRowClassName = (rule: RoleAccessRules): string => {
            //If intersection of rule roles and current user role has any common element
            if (rule.roles.filter(r => included.includes(r)).length > 0) {
                return "";
            }
            return "negative";
        }

        return <>
            <TitleBanner title={t("Your role settings")}></TitleBanner>
            {included.length > 0 && <StyledPositiveDiv>{t("You are: ") + included.join(", ")}</StyledPositiveDiv>}
            {excluded.length > 0 && <StyledNegativeDiv>{t("You are not: ") + excluded.join(", ")}</StyledNegativeDiv>}
            <h3 className="asecla-heading">{t("Your Access rights") as string}</h3>
            <StyledTable>
                <thead>
                    <tr><th>{t("Rule") as string}</th><th>{MANAGER}</th><th>{USER_ADMIN}</th></tr>
                </thead>
                <tbody>
                    {roleAccessRules.map(rule => <tr className={getRowClassName(rule)} key={rule.rule}>
                        <td>{rule.rule}</td>
                        {<Field roles={rule.roles} role={MANAGER}/>}
                        {<Field roles={rule.roles} role={USER_ADMIN}/>}
                    </tr>)}
                </tbody>
            </StyledTable>
            <h3 className="asecla-heading">{t("Your license rights") as string}</h3>
            {licenseCodes.filter(lc => lc.activeNow).map(lc => <div key={lc.id}>
                {t("License")}: {lc.licenseType.names[i18n.language]} {t("for")} {lc.licenseType.product.names[i18n.language]}
            </div>)}
            {licenseCodes.filter(lc => lc.activeNow).length == 0 && <>{t("You have no licenses actived for now")}</>}
        </>
    }

    return <PageStructure>
        <RoleSettings></RoleSettings>
    </PageStructure>
}
MyRolePage.CONTEXT_PATH = "myRole";

const StyledTable = styled.table`
    td {
        width: 25%;
    }
    tr > th {
        background-color: #4a86e8;
    }
    td:nth-child(1) {
        width: 50%;
    }
    tr.negative {
        background-color: #f4cccc;
    }
`;

const StyledPositiveCell = styled.td`
    background-color: #c9daf8;
`;

const StyledPositiveDiv = styled.div`
    background-color: #d9ead3;
`;

const StyledNegativeDiv = styled.div`
    background-color: #f4cccc;
`;


export default MyRolePage;