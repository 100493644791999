import React from "react";
import { useOrganizations } from "../../hooks/useOrganizations";
import { ContextProps } from "../../type/ContextProps";
import AseclaDataContext from "../../store/AseclaDataContext";
import { t } from "i18next";

type TitleBannerParams = {
    title: string;
    organization?: boolean;
}
function TitleBanner({title, organization = false}: TitleBannerParams) {
    const props: ContextProps = React.useContext(AseclaDataContext) as ContextProps;

    const { organizations, isFetched } = useOrganizations();

    const [curOrganization, errCheck] = props.getOrganizationWithChecks();
    let multipleOrganizations: boolean = organizations !== null && organizations.length > 1;

    return <div className="titlebar">
        {title}
        {organization && isFetched && multipleOrganizations && errCheck == null && <>
            {" " + t("of organization")} <i>{curOrganization!.name}</i>
        </>}
    </div>
}
export default TitleBanner;