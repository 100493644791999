import React, { useState } from "react";
import './DropdownCheckboxList.css'
import AseclaButton from "./AseclaButton";

export interface DropDownOption {
    label: string | JSX.Element;
    value: string;
    isChecked: boolean;
    headerKey: string;
}

interface DropdownCheckboxListParams {
    buttonText: string;
    options: DropDownOption[];
    onSelect: (changedOption: DropDownOption) => void;
}

export default function DropdownCheckboxList(props: DropdownCheckboxListParams) {
    const [isOpen, setIsOpen] = useState(false);
    const ulList = React.useRef<HTMLUListElement>(null);
    const [selectedOptions, setSelectedOptions] = useState<DropDownOption[]>(props.options);

    const handleOptionChange = (option: DropDownOption) => {
        const updatedOptions = selectedOptions.map((o) => {
            if (o.value === option.value) {
                return { ...o, isChecked: !o.isChecked };
            } else {
                return o;
            }
        });
        setSelectedOptions(updatedOptions);
        props.onSelect(option);
    };

    const toggleDropdown = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        setIsOpen(!isOpen);
        e.preventDefault();
    };

    React.useEffect(() => {
        const handleClick = (ev: MouseEvent) => {
            if (ulList.current && ulList.current.contains(ev.target as Node)) {
            } else if (ev.target instanceof HTMLButtonElement) {
            } else {
                setIsOpen(false);
            }
        };

        window.addEventListener('click', handleClick);

        return () => window.removeEventListener('click', handleClick);
    }, [isOpen]);

    return (
        <span className="dropdownCheckboxList">
            <AseclaButton className="small" action={e => toggleDropdown(e)}>{props.buttonText}</AseclaButton>
            {isOpen && (
                <ul ref={ulList}>
                    {props.options.map((option, index) => (
                        <li key={option.value}>
                        <input
                            id={"dropdown_" + option.value}
                            name={"dropdown_" + option.value}
                            type="checkbox"
                            checked={selectedOptions[index].isChecked}
                            onChange={() => handleOptionChange(option)}
                        />
                        &nbsp;
                        <label htmlFor={"dropdown_" + option.value}>{option.label}</label>
                        </li>
                    ))}
                </ul>
            )}
        </span>
    );
}
