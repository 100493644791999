
declare const KeycloakAuth: any;


export interface Authentication {
    login(): void;
    loginWithParams(pathName: string, register: boolean, urlParams: any): void;
    getUserMail(): string|null;
    isAuthenticated(): boolean;
    getToken(): string;
    provideTokenIfAuthenticated(): Promise<string|null>;
    initialized(): boolean;
}
let getAuthentication = (): Authentication => {

    const auth = (): ASECLA.Commons.Auth => {
        return ASECLA.Commons.Auth.getInstance();
    }

    const isAuthenticated = () : boolean => {
        return ASECLA.Commons.Auth.getInstance().isAuthenticated();
    }
    const getUserMail = () : string| null => {
        if ((window as any).keycloak) {
            return (window as any).keycloak.tokenParsed.email;
        }
        return null;
    }

    const provideTokenIfAuthenticated = () => {
        return auth() ? auth().provideAuthToken() : Promise.resolve(null)
    }

    const login = (): void => {
        auth().login();
    }

    const loginWithParams = (pathname: string, register: boolean, urlParams: any): void => {
        let redirectUri = buildURL(location.protocol + '//' + location.host + "/" + ((pathname) ? pathname : location.pathname), urlParams);
        if (register) {
            auth().register(redirectUri);
        } else {
            auth().loginPopup(redirectUri);
        }   
    }

    const buildURL = (domain: string, params: any[]) => {
        var url = new URL(domain);
        if (params) {
            for (let i = 0; i < params.length; i++) {
                url.searchParams.set(params[i].key, params[i].value);
            }
        }
        return url.toString();
    }

    return {
        getUserMail,
        isAuthenticated: isAuthenticated,
        getToken: () => (window as any).keycloak.token,
        login: login,
        loginWithParams: loginWithParams,
        provideTokenIfAuthenticated: provideTokenIfAuthenticated,
        initialized: () => ASECLA.Commons.Auth.getInstance().isInitialized(),
    }
}

export default getAuthentication;