import InvoiceData from "../InvoiceData";
import { LicenseCode } from "../LicenseCode";

export type OrganizationData = {
	id: number,
	name: string,
	haveManagerRights: boolean,
	haveAdminRights: boolean,
	/**
	 * Users are sent only for user with admin rights 
	 */
	users: string[],
	/**
	 * Admins are sent only for user with admin rights 
	 */
	admins: string[],
	/**
	 * Managers are sent only for user with manager rights 
	 */
	managers: string[],
	/**
	 * Licenses are sent only for user with manager rights 
	 */
	licenses: LicenseCode[],
	/**
	 * Discount is sent only for user with manager rights 
	 */
	discount: number,

	invoiceData: InvoiceData,
}

export const handleOrganizationDataResponse = (organization: OrganizationData): OrganizationData => {
	if (organization.licenses != null) {
		for (let licNo = 0; licNo < organization.licenses.length; licNo++) {
			let licenseCode: LicenseCode = organization.licenses[licNo];
			if (licenseCode.canClaimUntil) licenseCode.canClaimUntil = new Date(licenseCode.canClaimUntil);
			if (licenseCode.licenseStart) licenseCode.licenseStart = new Date(licenseCode.licenseStart);
			if (licenseCode.licenseEnd) licenseCode.licenseEnd = new Date(licenseCode.licenseEnd);
		}
	}
	return organization;
}