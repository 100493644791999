import { useQuery } from "@tanstack/react-query";
import { getUrl, prepareHeadersPromise } from "../store/ServerConnection";
import { UserOrder } from "../type/UserOrder";
import OrdersResponse, { handleUserOrdersResponse } from "../type/responses/OrdersResponse";
import getAuthentication from "../store/AuthenticationPageBase";
import { UserOrderHistory } from "../type/UserOrderHistory";
import UserOrderHistoryRequest from "../type/request/UserOrderHistoryRequest";
import { handleUserOrderHistoryResponse } from "../type/responses/UserOrderHistoryResponse";

export const TANSTACK_USER_ORDERS_KEY = "USER_ORDERS";
interface useUserOrdersResult {
    userOrders: UserOrder[],
    loaded: boolean,
    isError: boolean,
    queryKey: string[]
}
export function useUserOrders(full: boolean, organizationId?: number): useUserOrdersResult {
    const authentication = getAuthentication();

    let query = useQuery<OrdersResponse>({
        queryKey: [TANSTACK_USER_ORDERS_KEY, (full ? "" : organizationId)],
        staleTime: Infinity,
        cacheTime: Infinity,
        queryFn: async () => {
            if (!authentication.initialized() || !authentication.isAuthenticated() || (!full && organizationId == undefined)) return null;
            const requestOptions = {
                method: 'POST',
                headers: await prepareHeadersPromise(null, authentication),
            };
            if (organizationId != undefined) {
                (requestOptions as any).body = JSON.stringify({organizationId: organizationId});
            }
            const response = await fetch(getUrl(full ? "loadUserOrders" : "getUserOrders"), requestOptions);
            if (response.status != 200) {
                return Promise.reject(new Error());
            }
            return response.json().then(r => handleUserOrdersResponse(r) as any);
        }
    });

    return {
        userOrders: query.data == undefined ? [] : query.data.orders,
        loaded: !query.isFetching,
        isError: query.isError,
        queryKey: [TANSTACK_USER_ORDERS_KEY, (full ? "" : "" + organizationId)]
    }
}

interface useUserOrderHistoryResult {
    history: UserOrderHistory[],
    refetch: () => void,
    loaded: boolean,
    isError: boolean,
}
export function useUserOrderHistory(orders: UserOrder[], organizationId: number): useUserOrderHistoryResult[] {
    const authentication = getAuthentication();

    let userOrderQuery = orders.map(order => useQuery<UserOrderHistory[]|null>({
        queryKey: [TANSTACK_USER_ORDERS_KEY, "history", "" + order.orderId],
        staleTime: Infinity,
        cacheTime: Infinity,
        queryFn: async () => {
            if (!authentication.initialized() || !authentication.isAuthenticated()) return null;
            let req: UserOrderHistoryRequest = {
                organizationId: organizationId,
                userOrderId: order.orderId
            }
            const requestOptions = {
                method: 'POST',
                headers: await prepareHeadersPromise(null, authentication),
                body: JSON.stringify(req),
            };
            const response = await fetch(getUrl("getUserOrderHistory"), requestOptions);
            if (response.status != 200) {
                return Promise.reject(new Error());
            }
            return (await response.json().then(r => handleUserOrderHistoryResponse(r))).history;
        },
        enabled: false,
    }));

    let res: useUserOrderHistoryResult[] = (userOrderQuery ?? [undefined]).map(q => {return {
        history: q.data ?? [],
        loaded: !q.isFetching,
        isError: q.isError,
        refetch: q.refetch,
    }});
    return res;
}