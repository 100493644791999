import React from "react";
import AseclaDataContext from "../../../store/AseclaDataContext";
import { ContextProps } from "../../../type/ContextProps";
import { LicenseType } from "../../../type/LicenseType";
import { Product } from "../../../type/Product";
import LicTypeProperties from "./LicTypeProperties";
import { useTranslation } from 'react-i18next';
import CreateOrderLink from "./CreateOrderLink";
import GenerateLicenseCodeRequest from "../../../type/request/GenerateLicenseCodeRequest";
import UnitDiv from "../../utils/UnitDiv";
import AseclaButton from "../../utils/AseclaButton";
import { TANSTACK_LICENSE_TYPES_STATS_KEY, useLicenseTypesFullSet } from "../../../hooks/useLicenseTypes";
import { queryClient } from "../../../App";
import { useCreateNewLicenseType, useDeleteLicenseType, useGenerateLicenseCode, useModifyLicenseType } from "../../../hooks/mutations/useLicenseTypeMutations";
import Loading from "../../utils/Loading";
import { LicenseStatsResponse } from "../../../type/responses/LicenseStatsResponse";
import { LicenseStats } from "../../../type/LicenseStats";
import TitleBanner from "../../utils/TitleBanner";

const propertySubdiv = "PropertySubdiv";
const createOrderLinkSubdiv = "CreateOrderLink";
const generateCodeSubdiv = "generateCodeSubdiv";

type LicTypeData = {
    subdivToShow: string,
    generateCodeData: any,
}

type LicTypeCandidate = {
    technicalName: string,
    productId: number,
    includeInOffer: boolean,
}

function AseclaAdminLicType() {
    const props: ContextProps = React.useContext(AseclaDataContext) as ContextProps;
    const {t } = useTranslation();

    const { licenseStats, products, loaded, isError } = useLicenseTypesFullSet();
    const [licTypeData, setLicTypeData] = React.useState<LicTypeData[]>([]);
    const [licTypeCandidate, setLicTypeCandidate] = React.useState<LicTypeCandidate|null>(null);
   
    const { createLicenseType } = useCreateNewLicenseType();
    const { generateLicenseCode: generateLicenseCodeMutation } = useGenerateLicenseCode()
    const { deleteLicenseType } = useDeleteLicenseType();

    const modifyLicenseType = (licenseType: LicenseType): void => {
        let index = licenseStats.length;
        for (let i = 0; i < licenseStats.length; i++) {
            if (licenseStats[i].licenseType.id == licenseType.id) {
                index = i;
            }
        }
        let lts: LicenseStats[] = [...licenseStats];
        lts[index] = {
            licenseType: {...licenseType},
            noOfLicenseCodes: lts[index].noOfLicenseCodes,
        };
        setLicenseTypesStats(lts);
    }

    const { modifyLicenseType: modifyLicenseTypeMutation } = useModifyLicenseType(modifyLicenseType);

    React.useEffect(() => {
        if (licTypeData.length != licenseStats.length) {
            setLicTypeData(licenseStats.map(lt => {return {subdivToShow: "", generateCodeData: {}}}));
        }
    }, [licenseStats]);

    const freezePage = (): boolean => {
        return props.freezeHeaderCounter > 0;
    }

    const setLicenseTypesStats = (licenseStats: LicenseStats[]): void => {
        let resp: LicenseStatsResponse = {
            licenseStats: licenseStats,
            products: products,
            success: true,
        }
        queryClient.setQueryData(TANSTACK_LICENSE_TYPES_STATS_KEY, resp);
    }

    const setLicStatField = (no: number, fieldName: keyof LicenseType, value: any) : LicenseStats => {
        let licStats : LicenseStats[] = [...licenseStats];
        licStats[no] = {
            noOfLicenseCodes: licStats[no].noOfLicenseCodes,
            licenseType: {
                ...licStats[no].licenseType,
                [fieldName]: value
            }
        }
        setLicenseTypesStats(licStats);
        return licStats[no]
    }

    const getProduct = (productId: number): Product|null => {
        for (let i = 0; i < products.length; i++) {
            if (products[i].id === productId) {
                return products[i];
            }
        }
        return null;
    }

    const setGenerateCodeProperty = (no: number, property: string, value: any): void => {
        let lt: LicTypeData[] = [...licTypeData];
        lt[no] = {
            ...lt[no],
            generateCodeData: {... lt[no].generateCodeData, [property]: value}
        }
        setLicTypeData(lt);
    }

    const showPropertiesDiv = (no: number) => {
        let lt: LicTypeData[] = [...licTypeData];
        if (lt[no].subdivToShow === propertySubdiv) {
            lt[no].subdivToShow = "";
        } else {
            lt[no].subdivToShow = propertySubdiv;
        }
        setLicTypeData(lt);
    }

    const showGenerateCodeNo = (no: number) => {
        let lt: LicTypeData[] = [...licTypeData];
        if (lt[no].subdivToShow === generateCodeSubdiv) {
            lt[no].subdivToShow = "";
        } else {
            lt[no].subdivToShow = generateCodeSubdiv;
            if (licTypeData[no].generateCodeData === undefined) {
                lt[no].generateCodeData = {};
            }
        }

        setLicTypeData(lt);
    }

    const showGenerateOrderLink = (no: number) => {
        let lt: LicTypeData[] = [...licTypeData];
        if (lt[no].subdivToShow === createOrderLinkSubdiv) {
            lt[no].subdivToShow = "";
        } else {
            lt[no].subdivToShow = createOrderLinkSubdiv;
        }
        setLicTypeData(lt);
    }

    const createNewLicTypeClick = () => {
        createLicenseType({
            includeInOffer: licTypeCandidate?.includeInOffer ?? false,
            productId: licTypeCandidate?.productId!,
            technicalName: licTypeCandidate?.technicalName!
        });
        setLicTypeCandidate(null);
    }

    const save = (no: number) => {
        let licStats : LicenseStats = licenseStats[no];
        if (licStats.licenseType.product == null) {
            // licType = setProduct(no, getProductToShow(licenseTypes[no].product));
            licStats = setLicStatField(no, "product", getProduct(getProductToShow(licStats.licenseType.product)));
        }
        modifyLicenseTypeMutation({
            license: licStats.licenseType
        });
    }

    const generateLicenseCodeClick = (no: number) => {
        let values: any = licTypeData[no].generateCodeData;
        let request: GenerateLicenseCodeRequest = {
			licenseTypeId: licenseStats[no].licenseType.id!,
			noOfUsers: parseInt(values["noOfUsers"]),
			validUntil: values["validUntil"],
			length: parseInt(values["length"]),
            lengthUnit: licenseStats[no].licenseType.lengthUnit,
		}
        let callback = (code: string) => {
            let codes: string[] = [...licTypeData[no].generateCodeData["codes"] ?? []];
            codes.push(code);
            setGenerateCodeProperty(no, "codes", codes);
        }
        generateLicenseCodeMutation({callback, request});
    }

    const deleteLicenseTypeClick = (no: number) => {
        if (window.confirm(t("You are about to delete license...", {name: licenseStats[no].licenseType.technicalName}))) {
            deleteLicenseType({licenseTypeId: licenseStats[no].licenseType.id});
        };
    }

    const getProductToShow = (product: Product): number => {
        if (product == null) {
            if (products != null && products.length > 0) {
                return products[0].id;
            } else {
                return -1;
            }
        }
        return product.id;
    }

    const getLicenseTypeOrder = (lt1: LicenseType, lt2: LicenseType): number => {
        if (lt1.id === undefined && lt2.id !== undefined) {
            return 1;
        }
        if (lt1.id !== undefined && lt2.id === undefined) {
            return -1;
        }
        if (lt1.product != null && lt2.product != null && lt1.product.names["en"] > lt2.product.names["en"])
            return 1;
        else if (lt1.product != null && lt2.product != null && lt1.product.names["en"] < lt2.product.names["en"])
            return -1;
        else if (lt1.id! > lt2.id!)
            return 1;
        else if (lt1.id! < lt2.id!)
            return -1;
        return 0;
    }

    if (isError) {
        return <p>{t("Could not load data") as string}</p>
    }

    if (!loaded) {
        return <Loading></Loading>
    }

    return <div>
        <TitleBanner title={t("Asecla Admin Panel")}></TitleBanner>
        <h3 className="asecla-heading">{t("Existing license types") as string}</h3>
        <div>
            {licenseStats
                    .map((licStats : LicenseStats, index: number) => {
                        const licType = licStats.licenseType;

                        return <div key={"licenseTypeDiv" + index} className="shadow-box">
                            <div>
                                <span className="sideNote">({licType.id})</span>
                                <input value={licType.technicalName ?? ""} onChange={evt => setLicStatField(index, "technicalName", evt.target.value)} disabled={freezePage()}/>
                                <select className="small" value={getProductToShow(licType.product)} onChange={evt => setLicStatField(index, "product", getProduct(Number(evt.target.value)))} disabled={freezePage()}>
                                    {products.map((p: Product) =>
                                        <option key={p.id} value={p.id}>{p.names["en"] + "(" + p.id + ")"}</option>
                                    )}
                                </select>
                                <input type="checkbox" name={"includeInOffer" + index} checked={licType.includeInOffer ?? false} onChange={evt => setLicStatField(index, "includeInOffer", evt.target.checked)} disabled={freezePage()}/>
                                <label htmlFor={"includeInOffer" + index}>{t("Include in offer") as string}</label>
                            </div>
                            <div>
                                {licStats.noOfLicenseCodes == 0 && <span className="sideNote">{t("No license codes") as string}</span>}
                                {licStats.noOfLicenseCodes > 0 && <span>{t("{{no}} license codes generated", {no: licStats.noOfLicenseCodes}) as string}</span>}
                                <AseclaButton action={e => showPropertiesDiv(index)}>{t("Properties") as string}</AseclaButton>
                                <AseclaButton action={e => showGenerateCodeNo(index)}>{t("Generate code") as string}</AseclaButton>
                                <AseclaButton action={e => showGenerateOrderLink(index)}>{"Create order link" as string}</AseclaButton>
                                <AseclaButton action={e => save(index)}>{t("Save") as string}</AseclaButton>
                                <AseclaButton action={e => deleteLicenseTypeClick(index)}>{t("Delete") as string}</AseclaButton>
                            </div>
                            {licTypeData.hasOwnProperty(index) && licTypeData[index] !== undefined && licTypeData[index].subdivToShow === generateCodeSubdiv && licTypeData[index].generateCodeData !== undefined && <div>
                                {t("Number of users") as string}: <input value={licTypeData[index].generateCodeData.noOfUsers ?? 0} onChange={evt => setGenerateCodeProperty(index, "noOfUsers", evt.target.value)} disabled={freezePage()}/>
                                {t("Can be claimed until") as string}: <input type="Date" onChange={evt => setGenerateCodeProperty(index, "validUntil", new Date(evt.target.value))} disabled={freezePage()}/>
                                {t("Length") as string}: <input value={licTypeData[index].generateCodeData.length ?? 0} onChange={evt => setGenerateCodeProperty(index, "length", evt.target.value)} disabled={freezePage()}/>
                                <UnitDiv lengthUnit={licType.lengthUnit}></UnitDiv>
                                <AseclaButton action={e => generateLicenseCodeClick(index)}>{t("Generate") as string}</AseclaButton>
                                {(licTypeData[index].generateCodeData.codes ?? []).length > 0 &&
                                    <div><h3 className="asecla-heading">{t("Generated codes") as string}:</h3>
                                        {licTypeData[index].generateCodeData.codes.map((code: string) => <div key={code}>{code}</div>)}
                                    </div>
                                }
                            </div>}
                            {licTypeData[index] != undefined && <>
                                {licTypeData[index].subdivToShow === propertySubdiv && <>
                                    <LicTypeProperties licType={licType} licenseTypeIndex={index} modifyLicenseType={modifyLicenseType}></LicTypeProperties>
                                </>}
                                {licTypeData[index].subdivToShow === createOrderLinkSubdiv && <>
                                    <CreateOrderLink licType={licType}></CreateOrderLink>
                                </>}
                            </>}
                        </div>
                    }
            )}
            {licTypeCandidate != null && <div id={"newLicenseTypeDiv"}>
                <div>
                    <input value={licTypeCandidate.technicalName} onChange={evt => setLicTypeCandidate({...licTypeCandidate, technicalName: evt.target.value})} disabled={freezePage()}/>
                    <select className="small" value={licTypeCandidate.productId} onChange={evt => setLicTypeCandidate({...licTypeCandidate, productId: Number(evt.target.value)})} disabled={freezePage()}>
                        {products.map((p: Product) =>
                            <option key={p.id} value={p.id}>{p.names["en"] + "(" + p.id + ")"}</option>
                        )}
                    </select>
                    <input type="checkbox" name={"includeInOfferNew"} checked={licTypeCandidate.includeInOffer} onChange={evt => setLicTypeCandidate({...licTypeCandidate, includeInOffer: evt.target.checked})} disabled={freezePage()}/>
                    <label htmlFor={"includeInOfferNew"}>{t("Include in offer") as string}</label>
                    <AseclaButton action={e => createNewLicTypeClick()}>{t("Save") as string}</AseclaButton>
                    <AseclaButton action={e => setLicTypeCandidate(null)}>{t("Delete") as string}</AseclaButton>
                </div>
            </div>}
            <AseclaButton action={e => setLicTypeCandidate({technicalName:"", productId: products[0].id, includeInOffer: false})} enabled={!freezePage() && licTypeCandidate == null}>{t("Create new License Type...") as string}</AseclaButton>
        </div>
    </div>
}
export default AseclaAdminLicType;