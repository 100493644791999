import React from "react";
import { Notifications } from "../../integration/Notifications";
import AseclaDataContext from "../../store/AseclaDataContext";
import { ContextProps } from "../../type/ContextProps";
import { useMutation } from "@tanstack/react-query";
import { requestServer } from "../../store/ServerConnection";
import ClaimLicenseByIdRequest from "../../type/request/ClaimLicenseByIdRequest";
import { t } from "i18next";

export function useClaimLicense() {
    const { serverError, serverSuccess } = Notifications();
    const props = React.useContext(AseclaDataContext) as ContextProps;
    
    const { mutate: claimLicense } = useMutation({
        mutationFn: (req: ClaimLicenseByIdRequest) => {
            if (!props.authentication.initialized() || !props.authentication.isAuthenticated()) {
                return new Promise(() => null);
            }
            return requestServer("claimLicenseById", req, props);
        },
        onSuccess: () => serverSuccess(t("License succesfully claimed")),
        onError: (error: any, variables: ClaimLicenseByIdRequest) => {
            console.log(error);
            console.log(variables);
            serverError(t("Error while claiming license"))
        }
    });
  
    return { claimLicense };
}
