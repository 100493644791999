import React, { MouseEvent, ReactNode } from 'react';
import AseclaDataContext from '../../store/AseclaDataContext';
import { ContextProps } from '../../type/ContextProps';


type AseclaButtonIconParams = {
    icon: any,
    captions: string[],
    action: (e: MouseEvent<HTMLButtonElement>) => void,
    enabled?: boolean
    disableGUI?: boolean
    id?: string
    className?: string,
}
function AseclaButtonIcon({icon, captions, action, disableGUI = false, enabled = true, id, className = ""}: AseclaButtonIconParams) {
    const props: ContextProps = React.useContext(AseclaDataContext) as ContextProps;

    const freezePage = (): boolean => {
        return props.freezeHeaderCounter > 0;
    }

    const onClickAction = (e: MouseEvent<HTMLButtonElement>) => {
        if (disableGUI) {
            props.freezeHeader(true);
        }
        action(e);
    }

    return <button id={id} className={'asecla-button asecla-icon-caption-button ' + className} onClick={onClickAction} disabled={freezePage() || enabled === false}>
        <div className="button-content">
            <span className="plus-icon">{icon}</span>
            <div className="button-text">
                {captions.map(c => <span key={c}>{c}</span>)}
            </div>
        </div>
    </button>
}

export default AseclaButtonIcon;
