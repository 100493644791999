import React, { ReactFragment } from 'react';
import BigTable from './BigTable';
import { HeaderDefinition } from './definition/HeaderDefinition';
import { DataSource } from './definition/DataSource';
import { init } from 'i18next';

export type CellProp = {
    colSpan: number
}
export type Actions = {
    resetPagination: () => void;
}
type BigTableParams = {
    dataSource: DataSource,
    allHeaders: HeaderDefinition[],
    objects: any[],
    initPageSize: number,
    showPagination?: boolean,
    showPageSize?: boolean,
    otherHeaderButtons?: JSX.Element[],
    otherProps?: any,
    actions?: Actions,
}
/**
 * This type is to implement nice table, that receives the constant set of rows. Pagination is kind of fake here
 * we actually keep all rows in memory and display only given part.
 * If you want real pagination class, implement separate override of BigTable element
 */
function ConstantValuesBigTableFactory({otherHeaderButtons, dataSource, allHeaders, objects, initPageSize, showPagination, showPageSize, otherProps, actions}: BigTableParams) {
    const [pageNumber, setPageNumber] = React.useState<number>(0);
    const [pageSize, setPagesize] = React.useState<number>(initPageSize);

    if (actions != undefined) {
        actions.resetPagination = () => {
            setPageNumber(0);
            setPagesize(initPageSize);
        }
    }

    const noOfPages = Math.ceil(objects.length/pageSize);
    const canNextPage = pageNumber+1 < Math.ceil(objects.length / pageSize);
    const canPreviousPage = pageNumber > 0;

    return <BigTable
        dataSource={dataSource}
        allHeaders={allHeaders}
        loadData={() => {}}
        pageSize={pageSize}
        setPageSize={setPagesize}
        currentPageNo={pageNumber}
        setCurrentPageNo={setPageNumber}
        canNextPage={canNextPage}
        canPreviousPage={canPreviousPage}
        noOfPages={noOfPages}
        objects={objects}
        showPagination={showPagination}
        showPageSize={showPageSize}
        otherHeaderButtons={otherHeaderButtons}
        otherProps={otherProps}
    ></BigTable>
}
export default ConstantValuesBigTableFactory;