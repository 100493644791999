import React from "react";
import { Notifications } from "../../integration/Notifications";
import AseclaDataContext from "../../store/AseclaDataContext";
import { ContextProps } from "../../type/ContextProps";
import { useMutation } from "@tanstack/react-query";
import { requestServer } from "../../store/ServerConnection";
import AnonymousOrderCreationResponse from "../../type/responses/AnonymousOrderCreationResponse";
import ConfirmOrderPage from "../../page/ConfirmOrderPage";
import { LicenseType } from "../../type/LicenseType";
import AnonymousOrderRequest from "../../type/request/AnonymousOrderRequest";
import { t } from "i18next";
import { apiErrorHandling } from "../useErrorHandling";

interface useRegisterAnonymousOrderParams {
    licType: LicenseType,
    quantity?: number,
    length?: number,
    lengthUnit?: string,
    licTypePropValue: any,
    register: boolean,
}
export function useRegisterAnonymousOrder() {
    const { serverError } = Notifications();
    const props = React.useContext(AseclaDataContext) as ContextProps;
    const { successHandler } = apiErrorHandling({
        errMsg: t("Error while registering new order")
    });

    const { mutate: register, isError: registrationError, isSuccess: registrationSuccess } = useMutation({
        mutationFn: async ({ licType, quantity, length, lengthUnit, licTypePropValue}: useRegisterAnonymousOrderParams) => {
            let body: AnonymousOrderRequest = props.pricing.createAnonymousOrder(licType, quantity!, length!, lengthUnit!, licTypePropValue)
            return requestServer("registerAnonymousOrder", body, props);
        },
        onSuccess: successHandler((resp: AnonymousOrderCreationResponse, variables: useRegisterAnonymousOrderParams) => {
            props.authentication.loginWithParams(ConfirmOrderPage.CONTEXT_PATH, variables.register,
                [{
                    key: "order",
                    value: resp.orderId
                }]
            );
        }),
        onError: (error: any, variables: any) => {
            console.log(error);
            console.log("Can't register order");
            console.log(variables);
            serverError(t("Error while registering new order"))
        }
    });
  
    return { register, registrationError, registrationSuccess };
}
