import { PropertyQuestion } from "../component/offer/PropertyQuestion";
import i18n from "../i18n";
import { BonusCode } from "../type/BonusCode";
import { ContextProps } from "../type/ContextProps";
import InvoiceData from "../type/InvoiceData";
import { LicenseType } from "../type/LicenseType";
import { ProductPropertyValueAPI } from "../type/ProductPropertyValueAPI";
import AnonymousOrderRequest from "../type/request/AnonymousOrderRequest";
import CreateOrderRequest from "../type/request/CreateOrderRequest";

export interface Pricing {
    setContext(context: ContextProps): void;

    createOrderRequest(licTypePropValue: PropertyQuestion[]|undefined, licType: LicenseType, quantity: number, length: number, lengthUnit: string, invoiceData: InvoiceData, paymentMethod: string, bonusCode: BonusCode|null): CreateOrderRequest;
    createAnonymousOrder(licType: LicenseType, quantity: number, length: number, lengthUnit: string, licTypePropValue: PropertyQuestion[]|undefined): AnonymousOrderRequest;
}

export const pricingLogic = (): Pricing => {   
    let props: ContextProps;
    const setContext = (context: ContextProps): void => {
        props = context;
    }

    const getOrderProps = (licTypePropValue: PropertyQuestion[]|undefined): any[] => {
        let props: any[] = [];
        for (let i = 0; i < (licTypePropValue ? licTypePropValue.length : 0); i++) {
            let p: ProductPropertyValueAPI = {
                propertyId: licTypePropValue![i].propertyId,
                value: licTypePropValue![i].value
            };
            props.push(p);
        }
        return props;
    }
    
    const createOrderRequest = (licTypePropValue: PropertyQuestion[]|undefined, licType: LicenseType, quantity: number, length: number, lengthUnit: string, invoiceData: InvoiceData, paymentMethod: string, bonusCode: BonusCode|null): CreateOrderRequest => {
        return {
            ...createAnonymousOrder(licType, quantity, length, lengthUnit, licTypePropValue),
            organizationId: props.getCurrentOrganization()?.id!,
            invoiceData: invoiceData,
            paymentMethod: paymentMethod,
            language: i18n.language,
            bonusCodeValue: bonusCode === null ? null : bonusCode.theCode,
            bonusCodeId: bonusCode === null ? null : bonusCode.id
        }
    }

    const createAnonymousOrder = (licType: LicenseType, quantity: number, length: number, lengthUnit: string, licTypePropValue: PropertyQuestion[]|undefined): AnonymousOrderRequest => {
        return {
            licTypeId: licType.id!,
            props: getOrderProps(licTypePropValue),
            quantity: quantity,
            length: length,
            lengthUnit: lengthUnit,
        }
    }

    return {
        setContext,
        createOrderRequest,
        createAnonymousOrder,
    }
}