import { t } from "i18next";
import "./HelloOfferBox.css";
import styled from "styled-components";

interface HelloOfferBoxProps {
    onUserOrderSelectByName: (name: string) => void
}
function HelloOfferBox({onUserOrderSelectByName} : HelloOfferBoxProps) {
    return <div className="HelloOfferBox" onClick={() => onUserOrderSelectByName("Hello")}>
        <div className="box">
            <Selectable>
                <div>
                    <span>{t("HelloOfferBox_helloOffer") as string}</span>
                    <ul>
                        <li>{t("HelloOfferBox_noOfUsers") as string}&nbsp;&nbsp; / &nbsp;&nbsp;</li>
                        <li>{t("HelloOfferBox_attSize") as string}&nbsp;&nbsp; / &nbsp;&nbsp;</li>
                        <li>{t("HelloOfferBox_totalLimit") as string}</li>
                    </ul>
                </div>
                <span><a href="">{t("Select") as string}</a></span>
            </Selectable>
        </div>
    </div>
}

export default HelloOfferBox;

let Selectable = styled.div`
    &:hover {
        margin: -2px;
        margin: 8px !important;
        border: 2px solid black;
        box-shadow: 0 0 10px rgba(52, 152, 219, 0.5);
    }
`
