import { LicenseType } from "../../../type/LicenseType";
import React from "react";
import AseclaDataContext from "../../../store/AseclaDataContext";
import { ContextProps } from "../../../type/ContextProps";
import { ProductProperty } from "../../../type/ProductProperty";
import AseclaButton from "../../utils/AseclaButton";
import BonusCodeElement from "../../utils/BonusCodeElement";
import { BonusCode } from "../../../type/BonusCode";
import UnitDiv from "../../utils/UnitDiv";
import { useTranslation } from "react-i18next";
import { useGenerateOrderLink } from "../../../hooks/mutations/useGenerateOrderLink";
import Price from "../../offer/pricing/Price";

type CreateOrderLinkParams = {
    licType: LicenseType;
}

function CreateOrderLink({licType}: CreateOrderLinkParams) {
    const {t} = useTranslation();
    const props: ContextProps = React.useContext(AseclaDataContext) as ContextProps;
    const [vals, setVals] = React.useState<any>({});
    const [quantity, setQuantity] = React.useState<number>(10);
    const [length, setLength] = React.useState<number>(12);
    const [orderLink, setOrderLink] = React.useState<string>("");

    const [bonusCodeEnableBuy, setBonusCodeEnableBuy] = React.useState<boolean>(false);
    const [bonusCodeValid, setBonusCodeValid] = React.useState<boolean>(false);
    const [bonusCode, setBonusCode] = React.useState<BonusCode | null>(null);
    const [bonusCodeString, setBonusCodeString] = React.useState<string>(bonusCode?.theCode ?? "");
    const [hideBonusCode, setHideBonusCode] = React.useState<boolean>(true);
    const [lastVerifiedBonusCode, setLastVerifiedBonusCode] = React.useState<string|undefined>(undefined);

    const { generateOrderLink } = useGenerateOrderLink(setOrderLink);

    let unfilledProperties: ProductProperty[] = [];
    if (licType.product != null) {
        for (let key in Object.keys(licType.product.properties)) {
            let property: ProductProperty = licType.product.properties[key];
            if (licType.properties[property.propertyId] === undefined) {
                unfilledProperties.push(property);
            }
        }
    }
    
    const callGenerateOrderLink = (): void => {
        generateOrderLink({
            licenseTypeId: licType.id!,
            noOfUsers: quantity,
            length: length,
            lengthUnit: licType.lengthUnit,
            bonusCodeId: (!bonusCodeValid || bonusCode === null) ? -1 : bonusCode.id,
            hideBonusCode: hideBonusCode,
        })
    }

    const freezePage = (): boolean => {
        return props.freezeHeaderCounter > 0;
    }

    const getLicenseTypePropertyLabel = (propId: string) => {
        let propIdNum = Number(propId);
        for (let i = 0; i < licType.product.properties.length; i++) {
            if (licType.product.properties[i].propertyId === propIdNum)
                return licType.product.properties[i].name;
        }
        return "???";
    }

    const valsModified = (propertyId: number, value: string) => {
        let v = {...vals};
        v[propertyId] = value;
        setVals(v);
    }

    return <>
        {Object.keys(licType.properties).map((key: any) => <div key={licType.id + "_" + key}>
            {getLicenseTypePropertyLabel(key)}: {licType.properties[key]}<br></br>
        </div>)}
        {unfilledProperties.map((property: ProductProperty) => <div key={licType.id + "_" + property.propertyId}>
            {getLicenseTypePropertyLabel("" + property.propertyId)} - <input value={vals[property.propertyId] ?? ""} onChange={evt => valsModified(property.propertyId, evt.target.value)} disabled={freezePage()}/>
        </div>)}
        <div>
            {t("Quantity") as string}: <input type="number" value={quantity} onChange={e => setQuantity(Number(e.target.value))} id="quantity" name="quantity" min="1"  disabled={freezePage()}/><br></br>
        </div>
        <div>
            {t("Length") as string}: <input type="number" value={length} onChange={e => setLength(Number(e.target.value))} id="length" name="length" min="1" max="36"  disabled={freezePage()}/> <UnitDiv lengthUnit={licType.lengthUnit}></UnitDiv><br></br>
        </div>
        <div>
            {t("The price is") as string}: <b><Price licenseType={licType} quantity={quantity} length={length} bonusCodeValid={bonusCodeValid} bonusCode={(bonusCodeValid ? bonusCode : null) ?? undefined} showHint={false} showDiscount={false} includeOrganizationDiscount={false}></Price> {t("net") as string}.</b>
        </div>
        <div>
            <BonusCodeElement includeConsent={false} licType={licType} setBonusCodeEnableBuy={setBonusCodeEnableBuy} 
                setBonusCodeValid={setBonusCodeValid} bonusCode={bonusCode} setBonusCode={setBonusCode}
                bonusCodeString={bonusCodeString} setBonusCodeString={setBonusCodeString}
                lastVerifiedBonusCode={lastVerifiedBonusCode} setLastVerifiedBonusCode={setLastVerifiedBonusCode}></BonusCodeElement>
            {(bonusCodeValid && bonusCode != null) && <div>
                <input type="checkbox" checked={hideBonusCode} onChange={e => setHideBonusCode(e.target.checked)} name="hideBonusCodeInput" id="hideBonusCodeInput"  disabled={freezePage()}/>
                <label htmlFor="hideBonusCodeInput">{t("Hide bonuscode for user") as string}</label>
            </div>}
        </div>
        <AseclaButton action={callGenerateOrderLink} enabled={bonusCodeEnableBuy} disableGUI={true}>{t("Generate order link") as string}</AseclaButton>
        {orderLink!="" && <div >
            {t("Pass this link to the user") as string}: <a href={orderLink}>{orderLink}</a>
        </div>}
    </>
}

export default CreateOrderLink;
