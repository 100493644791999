import { AseclaStandardResponse } from "./AseclaStandardResponse";
import { OrganizationData, handleOrganizationDataResponse } from "./OrganizationData";

type BaseProps = AseclaStandardResponse;
interface OrganizationResponse extends BaseProps { 
    organizations : OrganizationData[],
    aseclaAdmin: boolean,
};
export default OrganizationResponse;

export const handleOrganizationResponse = (ret: OrganizationResponse): OrganizationResponse => {
	if (ret.organizations) {
		for (let orgNo = 0; orgNo < ret.organizations.length; orgNo++) {
			let org: OrganizationData = ret.organizations[orgNo];
			handleOrganizationDataResponse(org);
		}
	}
	return ret;
}