
import { ContextProps } from "../../type/ContextProps";
import { LicenseCode } from "../../type/LicenseCode";
import { useTranslation } from 'react-i18next';
import React, { MouseEvent } from "react";
import AseclaDataContext from "../../store/AseclaDataContext";
import { DataSource } from "../utils/bigTable/definition/DataSource";
import { CellProperty, HeaderDefinition } from "../utils/bigTable/definition/HeaderDefinition";
import ConstantValuesBigTableFactory from "../utils/bigTable/ConstantValuesBigTableFactory";
import { useNavigate, useSearchParams } from "react-router-dom";
import './LicenseDetails.css';
import { useClaimLicense } from "../../hooks/mutations/useClaimLicense";
import AseclaButton from "../utils/AseclaButton";
import ManageUsersPage from "../../page/ManageUsers";
import TitleBanner from "../utils/TitleBanner";

const predefineHeaders = (): HeaderDefinition[] => {
    const navigate = useNavigate();
    const {t} = useTranslation();
    let headers: HeaderDefinition[] = [];
    let { claimLicense } = useClaimLicense();

    const defineTableHeader = (props: HeaderDefinition) => {
        headers.push(props);
    }

    const get = (getValue: (code: LicenseCode) => any): (cellProperty: CellProperty, dataSource: DataSource) => any => {
        return (cellProperty: CellProperty, dataSource: DataSource) => getValue(cellProperty.object);
    }
    const formatDate = (d: Date|null): string => {
        if (d === undefined || d === null) return "";
        var months = [t('Jan'), t('Feb'), t('Mar'), t('Apr'), t('May'), t('Jun'), t('Jul'), t('Aug'), t('Sep'), t('Oct'), t('Nov'), t('Dec')];
        return d.getDate()  + "-" + months[d.getMonth()] + "-" + d.getFullYear();    
    }
    const getExpirationTime = (code: LicenseCode) => {
        if (code.licenseEnd === null) return "";
        if (code.licenseEnd.getTime() < new Date().getTime()) return t("Expired");
        return Math.ceil(Math.abs(code.licenseEnd.getTime() - new Date().getTime()) / (1000 * 60 * 60 * 24));
    }
    const activationStartValue = (cellProperty: CellProperty, dataSource: DataSource) => {
        let code: LicenseCode = cellProperty.object;
        let licStart = code.licenseStart === null ? null : new Date(code.licenseStart);
        if (licStart != null) {
            return formatDate(licStart);
        }
        if (!code.canClaimUntil || code.canClaimUntil > new Date()) {
            return <AseclaButton action={(e) => claimLicense({
                    licenseCodeId: code.id!,
                    organizationId: (dataSource.other as any).currOrganizationId!,
                })}>{t("Claim now") as string}</AseclaButton>
        }
        return "";
    }

    const actionsColumn = (cellProperty: CellProperty, dataSource: DataSource) => {
        let code: LicenseCode = cellProperty.object;
        let buyAction = () => {
            if (code == undefined || code.licenseType == undefined) {
                navigate("/aseclaOfferPage");
            } else {
                navigate("/aseclaOfferPage?tname=" + code.licenseType.technicalName);
            }
        };
        return <AseclaButton action={e => {buyAction(); e.preventDefault();}}>{t("Buy") as string}</AseclaButton>
    }

    const getAvailable = (code: LicenseCode) => {
        return <a onClick={e => navigate("/" + ManageUsersPage.CONTEXT_PATH)}>{code.usersNo}</a>;
    }

    defineTableHeader({headerKey: "ORDER_ID", display: false, displayedHeader: t("Order Id"), getCellContent: get((code) => code.orderId)});
    defineTableHeader({headerKey: "PRODUCT_NAME", display: true, displayedHeader: t("Product"), getCellContent: get((code) => code.licenseType.product?.names["en"])});
    defineTableHeader({headerKey: "LICENSE_NAME", display: true, displayedHeader: t("License"), getCellContent: get((code) => code.licenseType.names["en"])});
    defineTableHeader({headerKey: "ACTIVATION_START", display: true, displayedHeader: t("Activation start"), getCellContent: activationStartValue});
    defineTableHeader({headerKey: "ACTIVATION_END", display: true, displayedHeader: t("Activation end"), getCellContent: get((code) => formatDate(code.licenseEnd))});
    defineTableHeader({headerKey: "EXPIRES_IN", display: true, displayedHeader: t("Expires in [days]"), getCellContent: get(getExpirationTime)});
    defineTableHeader({headerKey: "AVAILABLE", display: true, displayedHeader: t("Available"), getCellContent: get(getAvailable)});
    defineTableHeader({headerKey: "ACTION", display: true, displayedHeader: t("Buy"), getCellContent: actionsColumn});

    return headers;
}


function LicensesDetails() {
    const props: ContextProps = React.useContext(AseclaDataContext) as ContextProps;
    const [searchParams, ] = useSearchParams();
    const [allHeaders, ] = React.useState<HeaderDefinition[]>(predefineHeaders());
    const [newLicenses, setNewLicenses] = React.useState<number[] | null>(null);
    const [showHidden, setShowHidden] = React.useState<boolean>(false);

    const {t} = useTranslation();

    React.useEffect(() => {
        const showNewLicenses = searchParams.get('showNewLicenses');
        if (showNewLicenses != null) {
            setNewLicenses(showNewLicenses.split(",").map(idStr => parseInt(idStr)));
        }
    }, [props.currentOrganizationNo]);

    const [curOrganization, errCheck] = props.getOrganizationWithChecks();
    if (errCheck != null) {
        return errCheck;
    }

    let allLicenses: LicenseCode[]|undefined = (curOrganization == undefined || curOrganization!.licenses == null) ? undefined : curOrganization!.licenses;

    let codes: LicenseCode[]|undefined = allLicenses == undefined ? undefined :
        allLicenses.filter(lc => showHidden || lc.licenseEnd == null || lc.licenseEnd.getTime() >= new Date().getTime());

    let expiredExists: boolean = allLicenses != undefined && allLicenses.find((licCode, i, all) => 
        licCode.licenseEnd != null && licCode.licenseEnd.getTime() < new Date().getTime()
    ) != undefined;


    let createNewTable = (licCodes: LicenseCode[]) => {

        let data: DataSource = {
            getCellClassname: (colNo, rowNo) => {
                if (newLicenses != null && newLicenses.indexOf(licCodes[rowNo].id) !== -1) {
                    return "newLicenseCode";
                } else {
                    return "";
                }
            },
            other: {
                currOrganizationId: curOrganization?.id
            }
        }

        let otherHeaderButtons = expiredExists ? [
            <AseclaButton className="small" action={e => setShowHidden(!showHidden)}>{(showHidden ? t("Expired shown") : t("Expired hidden")) as string}</AseclaButton>
        ] : [];

        return <>
            <ConstantValuesBigTableFactory
                dataSource={data}
                allHeaders={allHeaders}
                objects={licCodes}
                initPageSize={100}
                showPagination={false}
                showPageSize={false}
                otherProps={{otherButtonsRight: otherHeaderButtons}}
            ></ConstantValuesBigTableFactory>
        </>
    }

    return <>
        <TitleBanner title={t("License details")} organization={true}></TitleBanner>
        {newLicenses != null && <div>{t("Well done: you just got new licenses! Please see them marked below, they are inactive now. Start them if you want to use them already now.") as string}</div>}
        {codes != undefined && createNewTable(codes)}
    </>
}
export default LicensesDetails

