import './PropertiesDisplay.css'

export type PropertyDisplayElement = {
    displaydHeader: String | JSX.Element,
    value: String,
    className?: String
}
export type PropertyDisplayParams = {
    properties: PropertyDisplayElement[]
}
function PropertiesDisplay({properties}: PropertyDisplayParams) {
    return (
      <div className="property-container">
        {properties.map((property, index) => (
          <div key={index} className={["property-row", property.className ?? ""].join(" ")}>
            <div className="property-name">{property.displaydHeader}:</div>
            <div className="property-value">{property.value}</div>
          </div>
        ))}
      </div>
    );
};
export default PropertiesDisplay;