import React from "react";
import { queryClient } from "../../App";
import { Notifications } from "../../integration/Notifications";
import ProductsResponse from "../../type/responses/ProductsResponse";
import { TANSTACK_ADMIN_PRODUCTS_KEY } from "../queries/useAdminProducts";
import AseclaDataContext from "../../store/AseclaDataContext";
import { ContextProps } from "../../type/ContextProps";
import { useMutation } from "@tanstack/react-query";
import { requestServer } from "../../store/ServerConnection";
import { t } from "i18next";
import { Product } from "../../type/Product";

const success = (msg: string) => {
    const { serverSuccess } = Notifications();
  
    return (ret: ProductsResponse) => {
        queryClient.setQueryData(TANSTACK_ADMIN_PRODUCTS_KEY, ret.products);
        serverSuccess(msg);
    }
}

export function useSaveProduct(productSaved: (productId: number)=>void ) {
    const { serverError } = Notifications();
    const props = React.useContext(AseclaDataContext) as ContextProps;
  
    const { mutate: saveProduct } = useMutation({
      mutationFn: (product: Product) => {
        let req = {product: product};
        return requestServer("saveProduct", req, props);
      },
      onSuccess: (data: any, product: Product) => {productSaved(product.id); success(t("Product saved"))(data);},
      onError: (err) => serverError(t("Error while saving product {{err}}", {err: err}))
    });
  
    return { saveProduct };
}

export function useAddProduct() {
    const { serverError } = Notifications();
    const props = React.useContext(AseclaDataContext) as ContextProps;
  
    const { mutate: addProduct } = useMutation({
      mutationFn: (productName: string) => {
        let req = {productName: productName}
        return requestServer("addProduct", req, props);
      },
      onSuccess: success(t("Product added")),
      onError: (err) => serverError(t("Error while adding product {{err}}", {err: err}))
    });
  
    return { addProduct };
}

export function useDeleteProduct() {
    const { serverError } = Notifications();
    const props = React.useContext(AseclaDataContext) as ContextProps;
  
    const { mutate: deleteProduct } = useMutation({
      mutationFn: (productId: number) => {
        let req = {productId: productId}
        return requestServer("deleteProduct", req, props);
      },
      onSuccess: success(t("Product deleted")),
      onError: (err) => serverError(t("Error while deleting product {{err}}", {err: err}))
    });
  
    return { deleteProduct };
}
