import React from "react";
import { useTranslation } from 'react-i18next';
import UserInputAction from "../utils/UserInputAction";
import { OrganizationData } from "../../type/responses/OrganizationData";
import ManageUsersComponent from "../ManageUsersComponent";
import InvoiceData from "../../type/InvoiceData";
import AseclaButton from "../utils/AseclaButton";
import { InvoiceDataFormOnInvoiceData } from "../InvoiceDataForm";
import ModifyInvoiceDataRequest from "../../type/request/ModifyInvoiceDataRequest";
import { TANSTACK_ORGANIZATIONS_ADMIN_KEY, useAdminOrganizations } from "../../hooks/queries/useAdminOrganizations";
import IsErrorMessage from "../utils/IsErrorMessage";
import Loading from "../utils/Loading";
import { queryClient } from "../../App";
import { useChangeOrganizationDiscount, useDeleteOrganization, useModifyInvoiceData } from "../../hooks/mutations/useOrganizationOperations";
import CreateOrganization from "../CreateOrganization";
import styled from "styled-components";
import TitleBanner from "../utils/TitleBanner";

function AseclaAdminOrganizations() {

    const [ filter, setFilter] = React.useState<string>("");
    const [ curOrganizationId, setCurOrganizationId] = React.useState<number | null>(null);

    const [ organizationToEditInvoiceData, setOrganizationToEditInvoiceData] = React.useState<OrganizationData | null>(null);
    const [ organizationsInvoiceData, setOrganizationsInvoiceData] = React.useState<InvoiceData | null>(null);

    const { organizations, onOrganizationChanged, count: orgsCount, isFetching, isError } = useAdminOrganizations(filter);
    const { deleteOrganization } = useDeleteOrganization();
    const { modifyInvoiceData, validationError, clearValidationError } = useModifyInvoiceData(true, setOrganizationToEditInvoiceData);
    const { changeOrganizationDiscount } = useChangeOrganizationDiscount();

    const {t} = useTranslation();

    React.useEffect(() => {
        queryClient.invalidateQueries({queryKey: TANSTACK_ORGANIZATIONS_ADMIN_KEY});
    }, [filter]);

    const getCurrentOrganization = (): OrganizationData|null => {
        for (let i = 0; i < organizations.length; i++) {
            if (organizations[i].id == curOrganizationId) {
                return organizations[i];
            }
        }
        return null;
    }

    const deleteOrganizationClick = (organization: OrganizationData) => {
        let organizationName = prompt(t("Please provide name of organization to confirm you really want to delete it"), "");
        if (organizationName == organization.name) {
            deleteOrganization({organizationId: organization.id});
        }
    }

    const saveDateOrgAndClose = () => {
        let req: ModifyInvoiceDataRequest = {
            organizationId: organizationToEditInvoiceData?.id!,
            invoiceData: organizationsInvoiceData!
        }
        modifyInvoiceData(req);
    }

    const isDiscountValue = (text: string): boolean => {
        let val = parseInt(text);
        if (isNaN(val)) return false;
        return val >= 0 && val <= 100;
    }

    const saveDiscount = (organization: OrganizationData, newValue: string): void => {
        let val: number = parseInt(newValue);
        if (isNaN(val)) return;
        changeOrganizationDiscount({
            organizationId: organization.id,
            newDiscount: val,
            orgsCount,
            organizations,
        });
    }

    if (isFetching) return <Loading></Loading>
    if (isError) return <IsErrorMessage></IsErrorMessage>

    if (organizationToEditInvoiceData != null) {
        return <>
            <InvoiceDataFormOnInvoiceData
                invoiceData={organizationsInvoiceData!}
                setInvoiceData={invoiceData => setOrganizationsInvoiceData(invoiceData)}
                validationError={validationError}
            ></InvoiceDataFormOnInvoiceData>
            <ActionButtonsDiv>
                <AseclaButton action={saveDateOrgAndClose}>{t("Save Data and back to organizations") as string}</AseclaButton>
                <AseclaButton action={e => {clearValidationError(); setOrganizationToEditInvoiceData(null)}}>{t("Cancel Invoice Data edit") as string}</AseclaButton>
            </ActionButtonsDiv>
        </>
    }

    return <>
        <TitleBanner title={t("Organizations")}></TitleBanner>
        {curOrganizationId == null && <>
                <div>
                    {t("Filter organization name") as string}:
                    <UserInputAction
                        buttonAction={(userFilter) => setFilter(userFilter)}
                        buttonText="Filter"
                        value={filter}
                        preserveValueOnAction={true}
                    ></UserInputAction>
                </div>
                {[...organizations].sort((a, b) => a.name == b.name ? a.id -b.id : (a.name < b.name ? -1 : 1)).map((organization, index) => {
                    return <div key={organization.id} data-org-id={organization.id}>
                        ({organization.id})
                        <AseclaButton className="small" action={(e: any) => deleteOrganizationClick(organization)}>{t("Delete") as string}</AseclaButton>
                        <AseclaButton className="small" action={e => {setOrganizationToEditInvoiceData(organization); setOrganizationsInvoiceData({...organization.invoiceData});}}>{t("Edit invoice data") as string}</AseclaButton>
                        <AseclaButton className="small" action={(e) => setCurOrganizationId(organization.id)}>{t("Organization") as string}: {organization.name}</AseclaButton>
                        &nbsp;
                        <UserInputAction
                            buttonText={t("Save discount")}
                            value={(organization.discount ?? "0") + ""}
                            isValid={isDiscountValue}
                            buttonAction={txt => saveDiscount(organization, txt)}
                            disableButton={(txt) => txt === ((organization.discount ?? "0") + "")}
                            label2={"%"}
                            preserveValueOnAction={true}
                        ></UserInputAction>
                    </div>
                })}
                <hr></hr>
                <TitleBanner title={t("Create Organization")}></TitleBanner>
                <CreateOrganization onOrganizationCreated={undefined}></CreateOrganization>
            </>
        }
        {curOrganizationId != null && <>
                {t("You are managing Organizations {{organizationName}} as Admin.", {organizationName: getCurrentOrganization()!.name})}
                <AseclaButton className="small" action={() => setCurOrganizationId(null)}>{t("Back to organizations list") as string}</AseclaButton>
                <ManageUsersComponent
                    forcedOrganization={getCurrentOrganization()!}
                    setForcedOrganization={(ret) => onOrganizationChanged(ret, curOrganizationId!, filter)}
                ></ManageUsersComponent>
            </>
        }

    </>
}
export default AseclaAdminOrganizations;

const ActionButtonsDiv = styled.div`
    button {
        margin-left: 5px;
        margin-right: 5px
    }
    margin-top: 20px;
    margin-bottom: 50px;
`;