import { useQuery } from "@tanstack/react-query";
import { getUrl, prepareHeadersPromise } from "../../store/ServerConnection";
import { Product } from "../../type/Product";
import ProductsResponse from "../../type/responses/ProductsResponse";
import React from "react";
import AseclaDataContext from "../../store/AseclaDataContext";

export const TANSTACK_ADMIN_PRODUCTS_KEY = ["PRODUCTS", "ADMIN_FULL"];
interface useAdminProductsResult {
    products: Product[],
    isFetching: boolean,
    isError: boolean,
    queryKey: string[],
}
export function useAdminProducts(): useAdminProductsResult {
    const { authentication } = React.useContext(AseclaDataContext);

    let query = useQuery<Product[]|null>({
        queryKey: TANSTACK_ADMIN_PRODUCTS_KEY,
        staleTime: Infinity,
        cacheTime: Infinity,
        queryFn: async () => {
            if (authentication == undefined || !authentication.initialized() || !authentication.isAuthenticated()) return null;
            const requestOptions = {
                method: 'POST',
                headers: await prepareHeadersPromise(null, authentication),
            };
            let response = (await fetch(getUrl("loadProducts"), requestOptions));
            if (response.status != 200) {
                return Promise.reject(new Error());
            }
            return ((await response.json()) as ProductsResponse).products;
        }
    });

    return {
        products: query.data ?? [],
        isFetching: query.isFetching,
        isError: query.isError,
        queryKey: TANSTACK_ADMIN_PRODUCTS_KEY
    }
}