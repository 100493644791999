import React from "react";
import { useOrganizations } from "../../hooks/useOrganizations";
import HomePage from "../../page/Home";
import AseclaDataContext from "../../store/AseclaDataContext";
import { ContextProps } from "../../type/ContextProps";
import { OrganizationData } from "../../type/responses/OrganizationData";

export enum LicensesRole {
    "any",
    "manager",
    "admin",
    "aseclaAdmin"
}
interface PriviligesParams {
    children: any,
    roles: LicensesRole[],
}
function Priviliges({ roles, children }: PriviligesParams) {
    const props = React.useContext(AseclaDataContext) as ContextProps;
    const { isAseclaAdmin, organizations } = useOrganizations();

    let curOrganization : OrganizationData | null = props.currentOrganizationNo === -1 ? null : (organizations??[])[props.currentOrganizationNo];

    let roleFound : boolean = false;
    for (let i = 0; i < roles.length; i++) {
        if (roles[i] === LicensesRole.manager && curOrganization != null && curOrganization.haveManagerRights) {
            roleFound = true;
        }
        if (roles[i] === LicensesRole.admin && curOrganization != null && curOrganization.haveAdminRights) {
            roleFound = true;
        }
        if (roles[i] === LicensesRole.aseclaAdmin && isAseclaAdmin) {
            roleFound = true;
        }
    }

    if (!roleFound) {
        return <HomePage></HomePage>
    }

    return children;
}
export default Priviliges;