import './Hint.css';

interface HintParams {
    content: string
}
function Hint({content}: HintParams) {
    return <span className="hint hint-icon tooltip-outer">
        ?
        <div className="tooltip-inner">
            {content}
        </div>
    </span>
}

export default Hint;


