import { useQuery } from "@tanstack/react-query";
import { getUrl, prepareHeadersPromise } from "../../store/ServerConnection";
import React from "react";
import AseclaDataContext from "../../store/AseclaDataContext";
import MyRoleResponse from "../../type/responses/MyRoleResponse";
import { LicenseCode } from "../../type/LicenseCode";

export const MY_ROLE_KEY = ["MY_ROLE_KEY"];
interface useMyRoleResult {
    licenseCodes: LicenseCode[],
    isFetching: boolean,
    isError: boolean,
    queryKey: string[],
}
export function useMyRole(): useMyRoleResult {
    const { authentication } = React.useContext(AseclaDataContext);

    let query = useQuery<LicenseCode[]|null>({
        queryKey: MY_ROLE_KEY,
        staleTime: Infinity,
        cacheTime: Infinity,
        queryFn: async () => {
            if (authentication == undefined || !authentication.initialized() || !authentication.isAuthenticated()) return null;
            const requestOptions = {
                method: 'POST',
                headers: await prepareHeadersPromise(null, authentication),
            };
            let response = (await fetch(getUrl("getMyRole"), requestOptions));
            if (response.status != 200) {
                return Promise.reject(new Error());
            }
            return ((await response.json()) as MyRoleResponse).licenseCodes;
        }
    });

    return {
        licenseCodes: query.data ?? [],
        isFetching: query.isFetching,
        isError: query.isError,
        queryKey: MY_ROLE_KEY
    }
}