import { useQuery } from "@tanstack/react-query";
import { getUrl, prepareHeadersPromise } from "../../store/ServerConnection";
import { BonusCode, handleBonusCodesDates } from "../../type/BonusCode";
import { BonusCodeListResponse } from "../../type/responses/BonusCodeListResponse";
import React from "react";
import AseclaDataContext from "../../store/AseclaDataContext";
import ListBonusCodesRequest from "../../type/request/ListBonusCodesRequest";

export const TANSTACK_ADMIN_BONUS_CODE_KEY = (active: boolean) => ["BONUS_CODE", "ADMIN_FULL", "" + active];
interface useAdminBonusCodeResult {
    bonusCodes: BonusCode[],
    isFetching: boolean,
    isError: boolean,
    queryKey: string[],
}
export function useAdminBonusCodes(active: boolean): useAdminBonusCodeResult {
    const { authentication } = React.useContext(AseclaDataContext);

    let query = useQuery<BonusCode[]|null>({
        queryKey: ["BONUS_CODE", "ADMIN_FULL", "" + active],
        staleTime: Infinity,
        cacheTime: Infinity,
        queryFn: async () => {
            if (authentication == null || !authentication.initialized() || !authentication.isAuthenticated()) return null;
            let req: ListBonusCodesRequest = {
                active: active
            }
            const requestOptions = {
                method: 'POST',
                headers: await prepareHeadersPromise(null, authentication),
                body: JSON.stringify(req),
            };
            let response = await fetch(getUrl("listBonusCodes"), requestOptions);
            if (response.status != 200) {
                return Promise.reject(new Error());
            }
            return handleBonusCodesDates(((await response.json()) as BonusCodeListResponse).bonusCodes);
        }
    });

    return {
        bonusCodes: query.data ?? [],
        isFetching: query.isFetching,
        isError: query.isError,
        queryKey: TANSTACK_ADMIN_BONUS_CODE_KEY(active)
    }
}