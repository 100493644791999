import React from "react";
import AseclaDataContext from "../../../store/AseclaDataContext";
import { Product } from "../../../type/Product";
import { ContextProps } from "../../../type/ContextProps";
import UserInputAction from "../../utils/UserInputAction";
import "./AseclaAdminProducts.css";
import { ProductProperty } from "../../../type/ProductProperty";
import { useTranslation } from 'react-i18next';
import ProductPropertyLabel from "./ProductPropertyLabel";
import AseclaButton from "../../utils/AseclaButton";
import { TANSTACK_ADMIN_PRODUCTS_KEY, useAdminProducts } from "../../../hooks/queries/useAdminProducts";
import { useAddProduct, useDeleteProduct, useSaveProduct } from "../../../hooks/mutations/useAdminProducts";
import { queryClient } from "../../../App";
import Loading from "../../utils/Loading";
import IsErrorMessage from "../../utils/IsErrorMessage";
import styled from "styled-components";

function AseclaAdminProducts() {
    const props: ContextProps = React.useContext(AseclaDataContext) as ContextProps;
    const langs: string[] = props.getGUILangs();
    const { products, isFetching, isError } = useAdminProducts();
    const [blink, setBlink] = React.useState<any>({});
    const { saveProduct } = useSaveProduct(productId => setBlink({... blink, [productId]: undefined}));
    const { addProduct } = useAddProduct();
    const { deleteProduct } = useDeleteProduct();

    const {t} = useTranslation();

    const modifyProduct = (prodNo: number, p: Product) => {
        let prds: Product[] = [...products];
        prds[prodNo] = p;
        queryClient.setQueryData(TANSTACK_ADMIN_PRODUCTS_KEY, prds);
        setBlink({...blink, [p.id]: true})
    }

    const deleteProperty = (prodNo: number, propNo: number) => {
        let p: Product = {...products[prodNo]};
        p.properties = [... p.properties];
        p.properties.splice(propNo, 1);
        modifyProduct(prodNo, p);
    }

    const addProperty = (prodNo: number, property: string) => {
        let p: Product = {...products[prodNo]};
        p.properties = [... p.properties];
        p.properties.push({
            name: property,
            propertyId: -1,
            labels: {}
        } as ProductProperty);
        modifyProduct(prodNo, p);
    }

    const setLang = (prodNo: number, lang: string, name: string) => {
        let p: Product = {...products[prodNo]};
        p.names = {...p.names};
        p.names[lang] = name;
        modifyProduct(prodNo, p);
    }

    const setProductProperty = (prodNo: number, propNo: number, lang: string, value: string): void => {
        let p: Product = {...products[prodNo]};
        p.properties = [... p.properties];
        p.properties[propNo] = {...p.properties[propNo]};
        p.properties[propNo].labels = {...p.properties[propNo].labels};
        p.properties[propNo].labels[lang] = value;
        modifyProduct(prodNo, p);
    }

    const setProductTechnicalName = (prodNo: number, name: string): void => {
        let p: Product = {...products[prodNo]};
        p.technicalName = name;
        modifyProduct(prodNo, p);
    }

    if (isFetching) return <Loading></Loading>
    if (isError) return <IsErrorMessage></IsErrorMessage>

    return <>
        <div className="productsDiv">
            <ul>
                {products.map((product: Product, prodNo: number) => <ProductLI key={"p" + prodNo}><>
                    <AseclaButton action={e => saveProduct(product)} className={blink[product.id] ? "small blinking" : "small"}>{t("Save") as string}</AseclaButton>
                    {t("Product") as string} <input type="text" value={product.technicalName} onChange={e => setProductTechnicalName(prodNo, e.target.value)}></input>
                    <IdDiv className="hint">ID: {product.id}</IdDiv>
                    <AseclaButton action={e => deleteProduct(product.id)} className={"small"}>{t("Delete") as string}</AseclaButton>
                    <ul>
                        {langs.map((lang : string) => 
                            <li key={"Product_name" + prodNo + "_" + lang}>
                                {t("Product name") as string} {lang}: <input className="ProductPropertyLabel" value={products[prodNo].names[lang] ?? ""} onChange={evt => setLang(prodNo, lang, evt.target.value)}/>
                            </li>
                        )}
                        {product.properties.map((prop: ProductProperty, propNo: number) => <li key={"prop" + prodNo + "_" + propNo}>
                            {prop.name}<AseclaButton action={e => deleteProperty(prodNo, propNo)}>{t("Delete") as string}</AseclaButton>
                            <ul>{langs.map((lang : string) => 
                                <ProductPropertyLabel
                                    product={product}
                                    prodNo={prodNo}
                                    propNo={propNo}
                                    lang={lang}
                                    setProductProperty={setProductProperty}
                                    key={prodNo+"_" + propNo + "_" + lang}
                                ></ProductPropertyLabel>)
                            }</ul>
                        </li>)}
                        <li><UserInputAction buttonAction={(inputText: string) => addProperty(prodNo, inputText)} buttonText={t("Add Property")}></UserInputAction></li>
                    </ul>
                </></ProductLI>)}
                <li><UserInputAction buttonAction={(inputText: string) => addProduct(inputText)} buttonText={t("Add Product")}></UserInputAction></li>
            </ul>
        </div>
    </>
}
export default AseclaAdminProducts;

let ProductLI = styled.li `
    padding-bottom: 30px;
`

let IdDiv = styled.div `
    display: inline-block;
    padding-right: 30px;
`