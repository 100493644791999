import React from "react";
import { ContextProps } from "../../type/ContextProps";
import { useTranslation } from 'react-i18next';
import AseclaDataContext from "../../store/AseclaDataContext";
import UserOrderTable from "../orders/UserOrderTable";
import { useUserOrders } from "../../hooks/useUserOrders";
import { queryClient } from "../../App";
import Loading from "../utils/Loading";

function LicenseByOrder() {
    const props: ContextProps = React.useContext(AseclaDataContext) as ContextProps;

    const {t} = useTranslation();
    const {userOrders, loaded, queryKey } = useUserOrders(false, props.getCurrentOrganization()?.id);

    if (!loaded) {
        return <Loading></Loading>
    }

    return <>
        <UserOrderTable
            orders={userOrders}
            aseclaAdminModule={false}
            refresh={() => queryClient.invalidateQueries({ queryKey: [queryKey] })}
        ></UserOrderTable>
    </>
}
export default LicenseByOrder;