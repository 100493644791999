import React from "react";
import { Notifications } from "../../integration/Notifications";
import AseclaDataContext from "../../store/AseclaDataContext";
import { ContextProps } from "../../type/ContextProps";
import { UserOrder } from "../../type/UserOrder";
import { useMutation } from "@tanstack/react-query";
import { requestServer } from "../../store/ServerConnection";
import TakeControlOverOrderRequest from "../../type/request/TakeControlOverOrderRequest";
import OrdersResponse from "../../type/responses/OrdersResponse";
import OrderLicenseCodeRequest from "../../type/request/OrderLicenseCodeRequest";
import OrderCreatedResponse from "../../type/responses/OrderCreatedResponse";
import { NavigateFunction, useNavigate } from "react-router-dom";
import LicensesDetailsPage from "../../page/LicensesDetailsPage";
import CreateOrderRequest from "../../type/request/CreateOrderRequest";
import { t } from "i18next";
import { apiErrorHandling } from "../useErrorHandling";
import { queryClient } from "../../App";
import { TANSTACK_USER_ORDERS_KEY } from "../useUserOrders";

interface useTargetOrderParams {
    orderId: number,
    organizationConfirmed: boolean,
}
export function useTargetOrder(setOrder: (Order: UserOrder) => void) {
    const { serverError } = Notifications();
    const props = React.useContext(AseclaDataContext) as ContextProps;
    const { successHandler } = apiErrorHandling({
        errMsg: t("Error while loading order")        
    });
    
    const { mutate: takeControlOverOrder, isError: errorOnTakingOver, isSuccess: tookOverOrder } = useMutation({
        mutationFn: async ({orderId, organizationConfirmed}: useTargetOrderParams) => {
            if (!props.authentication.initialized() || !props.authentication.isAuthenticated() || !organizationConfirmed) {
                return new Promise(() => null);
            }
            let body: TakeControlOverOrderRequest = {
                orderId: orderId as number,
                organizationId: props.getCurrentOrganization()?.id!
            };
            return requestServer("takeControlOverOrder", body, props);
        },
        onSuccess: successHandler((resp: OrdersResponse) => {
            setOrder(resp.orders[0]);
        }),
        onError: (error: any, variables: useTargetOrderParams, context: any) => {
            console.log(error);
            console.log("Can't load order");
            console.log(variables);
            console.log(context);
            serverError(t("Error while loading order"))
        }
    });
  
    return { takeControlOverOrder, errorOnTakingOver, tookOverOrder };
}


const onOrderSuccess = (ret: OrderCreatedResponse, setOrderSent: (orderSent: boolean) => void, navigate: NavigateFunction) => { 
    if (ret.licenseCodesIds != null) {
        navigate("/" + LicensesDetailsPage.CONTEXT_PATH + "?showNewLicenses=" + ret.licenseCodesIds.join(","));
    } else {
        setOrderSent(true)
    }
}
export function useOrderLicenseCode(setOrderSent: (orderSent: boolean) => void) {
    const { serverSuccess } = Notifications();
    const navigate = useNavigate();
    const { mutationWithErrHandling } = apiErrorHandling({
        errMsg: t("Error ordering LicenseCode"),
        createRequest: (req: OrderLicenseCodeRequest) => req
    });

    const { mutate: orderLicenseCode, isLoading } = mutationWithErrHandling("orderLicenseCode",
        (resp: OrderCreatedResponse) => {
            onOrderSuccess(resp, setOrderSent, navigate);
            serverSuccess(t("Order sent")); 
        }
    );
  
    return { orderLicenseCode, isLoading };
}

export function useCreateLicenseCodeOrder(setOrderSent: (orderSent: boolean) => void) {
    const { serverSuccess } = Notifications();
    const navigate = useNavigate();
    const { mutationWithErrHandling } = apiErrorHandling({
        errMsg: t("Error while creating license code order"),
        createRequest: (req: CreateOrderRequest) => req
    });
    
    const { mutate: createLicenseCodeOrder, isLoading} = mutationWithErrHandling("createLicenseCodeOrder",
        (resp: OrderCreatedResponse) => {
            onOrderSuccess(resp, setOrderSent, navigate);
            serverSuccess(t("License Code order created"));
            queryClient.invalidateQueries([TANSTACK_USER_ORDERS_KEY]);
        }
    );
  
    return { createLicenseCodeOrder, isLoading };
}
