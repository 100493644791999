import React from "react";
import { ContextProps } from "../../type/ContextProps";
import AseclaDataContext from "../../store/AseclaDataContext";
import styled from "styled-components";
import edit from '../../assets/edit.png';
import accept from '../../assets/accept.png';
import decline from '../../assets/decline.png';

type InputEditOnDemandParams = {
    buttonText: string,
    onSaveAction: (text: string) => void,
    value?: string,
    disabled?: boolean,
}

function InputEditOnDemand(params: InputEditOnDemandParams) {
    const props: ContextProps = React.useContext(AseclaDataContext) as ContextProps;
    const [textValue, setTextValue] = React.useState<string>(params.value ?? "");
    const [isEdit, setEdit] = React.useState<boolean>(false);

    const freeze = () => {
        return props.freezeHeaderCounter > 0;
    }

    const saveAction = (e?: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        params.onSaveAction(textValue);
        setEdit(false);
    }

    const cancelAction = (e?: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        setTextValue(params.value ?? "");
        setEdit(false);
    }
    
    const disableButtonFun = (): boolean => {
        return freeze() || (params.disabled ?? false);
    }

    return <>
        <input
            value={textValue}
            onChange={e => setTextValue(e.target.value)}
            disabled={!isEdit || (freeze() || (params.disabled ?? false))}
            onKeyDown={(e) => {if (e.key === 'Enter') saveAction(undefined)}}
        />
        {!isEdit && <IconAnchor className="small" onClick={(e:any) => setEdit(true)}><img src={edit} alt="Edit" /></IconAnchor>}
        {isEdit && <>
            <IconAnchor className="small" onClick={saveAction}><img src={accept} alt="Accept" /></IconAnchor>
            <IconAnchor className="small" onClick={cancelAction}><img src={decline} alt="Decline" /></IconAnchor>
        </>}
    </>
}

export default InputEditOnDemand;

const IconAnchor = styled.a`
    img {
        width: 27px;
        height: 27px;
    }

    img:hover {
        width: 31px;
        height: 31px;
    }

    width: 27px;
    height: 27px;
    vertical-align: -8px;
`;