
import HomeBanner from "../home/HomeBanner";
import AseclaProductsTable from "./AseclaProductsTable";
import styled from "styled-components";
import HelloOfferBox from "../home/HelloOfferBox";

interface AseclaOfferProductTableProps {
    onUserOrderSelectByName: (name: string) => void
}
function AseclaOfferProductTable({ onUserOrderSelectByName}: AseclaOfferProductTableProps ) {
    return <><HomeBanner></HomeBanner>
            <Spacer></Spacer>

            <AseclaProductsTable onUserOrderSelectByName={onUserOrderSelectByName}></AseclaProductsTable>
            <HelloOfferBox onUserOrderSelectByName={onUserOrderSelectByName}></HelloOfferBox>
        </>
}
export default AseclaOfferProductTable;

const Spacer = styled.div`
  height:80px;
`;
