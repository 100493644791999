import { t } from "i18next";
import Hint from "./Hint";

interface HeaderWithHintParams {
    headerKey: string;
} 
function HeaderWithHint({headerKey}: HeaderWithHintParams) {
    return <>
        {t(headerKey)}
        <Hint content={t(headerKey + "_Hint")}></Hint>
    </>
}
export default HeaderWithHint;